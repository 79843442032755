import * as React from "react";

import { StarIcon, GitForkIcon } from "@primer/octicons-react";

export { StarIcon, GitForkIcon };

export const PaletteIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M425.705,86.587A238.427,238.427,0,0,0,256,16.292c-63.856,0-123.95,21.059-169.212,59.3C41.14,114.154,16,165.544,16,220.292c0,52.418,23.319,102.223,65.663,140.24,41.8,37.534,98.163,60.027,158.7,63.335a8.039,8.039,0,0,1,7.64,7.987V471.7a24.019,24.019,0,0,0,24.054,24q.873,0,1.747-.064a240.007,240.007,0,0,0,151.9-409.055ZM120,208a40,40,0,1,1,40-40A40,40,0,0,1,120,208Zm112-64a40,40,0,1,1,40-40A40,40,0,0,1,232,144Zm80-8a40,40,0,1,1,40,40A40,40,0,0,1,312,136Zm96,152a40,40,0,1,1,40-40A40,40,0,0,1,408,288Z"
    />
  </svg>
);

export const ExpoIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M318.1,61.8c-18.9-28.7-23.3-32.5-61.7-32.5h-0.4h-0.4c-38.4,0-42.6,3.8-61.7,32.5C176,88.8,0,411.1,0,420.1
		c0,13,3.4,25.4,15.5,42.9c12.3,17.7,33.7,27.6,49.2,11.9c10.5-10.6,123.5-205.2,178-278.8c6.5-9.2,20-9.2,26.7,0
		c54.5,73.6,167.4,268.2,178,278.8c15.5,15.7,36.8,5.8,49.2-11.9c12.1-17.5,15.5-29.9,15.5-42.9C511.8,411.1,335.8,88.6,318.1,61.8z
		"
    />
  </svg>
);
export const GitHubIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M256,11.4C116.1,10,1.5,122.2,0,262.1C0.5,371,71.2,467.2,175,500.2c12.8,2.3,17.5-5.4,17.5-12.1
		s0-21.6,0-42.6c-71.2,15.1-86.3-33.5-86.3-33.5c-4.7-15.3-14.8-28.3-28.4-36.8c-23.3-15.4,1.9-15.1,1.9-15.1
		c16.3,2.2,30.7,11.7,39.1,25.8c15,26.1,48.1,35.3,74.5,20.7c1.3-12.7,7.1-24.6,16.3-33.5c-56.8-6.3-116.4-27.7-116.4-123.8
		c-0.5-25.1,8.8-49.4,25.8-67.7c-7.8-21.5-6.9-45.2,2.6-66.1c0,0,21.6-6.8,69.8,25.6c41.9-11.2,86.1-11.2,128,0
		c48.9-32.4,69.8-25.6,69.8-25.6c9.4,20.9,10.3,44.6,2.6,66.1c17.5,18,27.2,42.1,27.2,67.3c0,96.3-60,117.5-116.4,123.8
		c12.5,12.1,18.9,29.2,17.5,46.5c0,33.5,0,60.5,0,68.7c0,8.1,4.7,14.7,17.5,12.1C440.9,466.7,511.4,370.8,512,262.1
		C510.5,122.2,395.9,10,256,11.4"
    />
  </svg>
);
export const LinkedInIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M436.3,436.3h-75.9V317.5c0-28.3-0.5-64.8-39.4-64.8c-39.5,0-45.5,30.9-45.5,62.8v120.8h-75.9V191.9h72.8v33.4
		h1c10.1-19.2,34.9-39.5,71.8-39.5c76.9,0,91.1,50.6,91.1,116.4V436.3z M113.9,158.6c-24.4,0-44.1-19.7-44.1-44.1
		c0-24.3,19.7-44,44.1-44c24.3,0,44,19.7,44,44C157.9,138.8,138.2,158.6,113.9,158.6z M151.9,436.3h-76V191.9h76V436.3z M490.7,0
		H21.3C9.6,0,0,9.6,0,21.3v469.3C0,502.4,9.6,512,21.3,512h469.3c11.8,0,21.3-9.6,21.3-21.3V21.3C512,9.6,502.4,0,490.7,0z"
    />
  </svg>
);
export const FacebookIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M324.6,0c-72.1,0-114.4,38.1-114.4,124.8v76.3h-91.4v91.4h91.4V512h91.4V292.6h73.1l18.3-91.4h-91.4v-60.9
		c0-32.7,10.7-48.9,41.3-48.9h50.1V3.8C384.5,2.6,359.2,0,324.6,0z"
    />
  </svg>
);
export const TwitterIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M512,97.2c-18.8,8.4-39.1,14-60.3,16.5c21.7-13,38.3-33.6,46.2-58.1c-20.3,12.1-42.8,20.8-66.7,25.5
		c-19.2-20.4-46.5-33.2-76.7-33.2c-58,0-105.1,47-105.1,105c0,8.2,0.9,16.3,2.7,23.9c-87.3-4.4-164.7-46.2-216.5-109.8
		c-9,15.5-14.2,33.6-14.2,52.8c0,36.4,18.5,68.6,46.7,87.4c-17.2-0.6-33.4-5.3-47.6-13.1v1.3c0,50.9,36.2,93.4,84.3,103
		c-8.8,2.4-18.1,3.7-27.7,3.7c-6.8,0-13.4-0.7-19.8-1.9c13.4,41.7,52.2,72.1,98.1,73c-36,28.2-81.3,44.9-130.5,44.9
		c-8.5,0-16.9-0.5-25.1-1.4c46.5,29.8,101.7,47.2,161,47.2c193.2,0,298.9-160.1,298.9-298.9c0-4.6-0.1-9.1-0.3-13.6
		C480.1,136.8,497.9,118.3,512,97.2"
    />
  </svg>
);
export const InstagramIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M256,46.4c67.7,0,76.1,0,103,1.9c25,0.9,38,5.6,47.3,8.3c12.1,4.6,20.4,10.2,29.7,19.5s14.8,17.6,19.5,29.7
		c3.7,9.3,7.4,22.3,8.3,47.3c0.9,26.9,1.9,35.2,1.9,103s0,76.1-1.9,103c-0.9,25-5.6,38-8.3,47.3c-4.6,12.1-10.2,20.4-19.5,29.7
		s-17.6,14.8-29.7,19.5c-9.3,3.7-22.3,7.4-47.3,8.3c-26.9,0.9-35.2,1.9-103,1.9s-76.1,0-103-1.9c-25-0.9-38-5.6-47.3-8.3
		c-12.1-4.6-20.4-10.2-29.7-19.5s-14.8-17.6-19.5-29.7c-3.7-9.3-7.4-22.3-8.3-47.3c-0.9-26.9-1.9-35.2-1.9-103s0-76.1,1.9-103
		c0.9-25,5.6-38,8.3-47.3c4.6-12.1,10.2-20.4,19.5-29.7s17.6-14.8,29.7-19.5c9.3-3.7,22.3-7.4,47.3-8.3
		C179.9,46.4,188.3,46.4,256,46.4 M256,0c-69.6,0-77.9,0-104.8,1.9C124.3,2.8,105.7,7.4,89,13.9c-16.7,6.5-30.6,14.8-45.4,29.7
		C29.7,57.5,20.4,72.3,13.9,89c-6.5,15.8-10.2,34.3-12.1,62.1C0.9,178.1,0,187.4,0,256c0,69.6,0,77.9,1.9,104.8
		c0.9,26.9,5.6,45.4,12.1,62.1c6.5,16.7,14.8,30.6,29.7,45.4c13.9,13.9,28.8,23.2,45.4,29.7c15.8,6.5,34.3,10.2,62.1,12.1
		c26.9,0.9,36.2,1.9,104.8,1.9s77.9,0,104.8-1.9c26.9-0.9,45.4-5.6,62.1-12.1c16.7-6.5,30.6-14.8,45.4-29.7
		c13.9-13.9,23.2-28.8,29.7-45.4c6.5-15.8,10.2-34.3,12.1-62.1c0.9-26.9,1.9-36.2,1.9-104.8s0-77.9-1.9-104.8
		c-0.9-26.9-5.6-45.4-12.1-62.1c-6.5-16.7-14.8-30.6-29.7-45.4c-13.9-13.9-28.8-23.2-45.4-29.7c-15.8-6.5-34.3-10.2-62.1-12.1
		C333.9,0,325.6,0,256,0L256,0z"
    />
    <path
      fill="currentcolor"
      d="M256,124.3c-72.3,0-130.8,58.4-130.8,130.8S183.7,385.9,256,385.9s130.8-58.4,130.8-130.8S328.3,124.3,256,124.3z
		 M256,340.4c-47.3,0-85.3-38-85.3-85.3s38-85.3,85.3-85.3s85.3,38,85.3,85.3S303.3,340.4,256,340.4z"
    />
    <circle fill="currentcolor" cx="392.3" cy="118.7" r="30.6" />
  </svg>
);
export const SpotifyIcon = () => (
  <svg viewBox="0 0 512 512">
    <path
      fill="currentcolor"
      d="M407.4,226.9c-82.5-49-218.6-53.5-297.4-29.6c-12.6,3.8-26-3.3-29.9-15.9c-3.8-12.7,3.3-26,16-29.9
		c90.4-27.4,240.8-22.1,335.8,34.3c11.4,6.8,15.1,21.4,8.4,32.8C433.5,230,418.8,233.7,407.4,226.9 M404.7,299.5
		c-5.8,9.4-18.1,12.3-27.4,6.6c-68.8-42.3-173.7-54.5-255.1-29.8c-10.6,3.2-21.7-2.8-24.9-13.3c-3.2-10.6,2.8-21.7,13.3-24.9
		c93-28.2,208.6-14.6,287.6,34C407.5,277.9,410.5,290.2,404.7,299.5 M373.4,369.2c-4.6,7.5-14.4,9.9-21.9,5.3
		c-60.1-36.7-135.8-45-224.9-24.7c-8.6,2-17.1-3.4-19.1-12c-2-8.6,3.4-17.1,12-19.1c97.5-22.3,181.2-12.7,248.6,28.5
		C375.6,351.9,378,361.7,373.4,369.2 M256,0C114.6,0,0,114.6,0,256c0,141.4,114.6,256,256,256s256-114.6,256-256
		C512,114.6,397.4,0,256,0"
    />
  </svg>
);
export const MailIcon = () => (
  <svg viewBox="0 0 32 32">
    <path
      fill="currentcolor"
      d="M0,4v24h32V4H0z M28.149,6l-12.151,9.719L3.851,6H28.149z M2,25.999V7.081l13.998,11.197L29.999,7.081v18.918H2z"
    />
  </svg>
);
